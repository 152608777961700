import '@splidejs/react-splide/css/core';
import React from "react";
import { CButton, FictionCarousel, NonFictionCarousel, MostDemandCarousel, HomeHeroCarousel } from "../Components"
import { urlPrefix } from '../Helpers/Common';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { terminateSession } from '../../Redux/Slices/UserSlice';
import { categoryIds } from "../../Config/Constant"

const Home = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.UserReducer.value);
  const navigate = useNavigate();

  const fxToRun = () => {
    dispatch(terminateSession())
    navigate(urlPrefix() + "/")
  }

  return (
    <React.Fragment>
      <section className="home-hero">
        <div className="house_container">
          <div className="hero-row">
            <div className="hero-left">
              <div className="sub-title">
                <h4 className="heading-h4">Featured Booklist</h4>
              </div>
              <div className="title">
                {/* <h1 className="heading-h1">Join house <span className="small">of</span> books today!</h1> */}
                {/* commented on 29 nov 2024 */}
                {/* <h1 className="heading-h1">A Prized Collection</h1> */}
                <h1 className="heading-h1">Your Local Library</h1>
              </div>
              <div className="disc">
                {/* <p><span className="dis-highlight">The Booker Prize 2024 will be announced this month.</span> So we felt there's no better time to showcase our selection of titles that have previously won this prestigious literary award. Now, our personal favourite is Shuggie Bain, but don't let that sway you.</p> */}
                <p><span className="dis-highlight">A good read means everything to us,</span>That's why we have a curated collection of acclaimed titles for readers to explore and enjoy. We are sure you'll find a few that mean something to you. In this age of bite-sized entertainment, we think there's still a place for compelling literature. Join us in bringing books back in conversation.</p>
              </div>

              <div className="show-mobile">
                <CButton fxToRun={fxToRun} redirectTo={!user.authenticated ? "register" : false} label={user.authenticated ? "Logout" : "Become a Member"} custClassName="header-btn-y t-btn"></CButton>
                <CButton redirectTo={!user.authenticated ? "login" : "subscription-account"} label={!user.authenticated ? "Login" : "My Account"} custClassName="header-btn-y t-btn"></CButton>
              </div>

              <div className="new-to-library">
                <div className="new-to">
                  <div className="library">
                    {/* <h5 className="heading-h5">The Booker Prize Winners</h5> */}
                    <h5 className="heading-h5">Featured Booklist</h5>
                  </div>
                  <div className="blank">
                    <p>&nbsp;</p>
                  </div>
                  <div className="date-time">
                    <p>02 / 05</p>
                  </div>
                </div>
                <div className="explore">
                  <CButton fxToRun={() => navigate(urlPrefix() + "/library-overview?cat=" + categoryIds.featured)} label="See More" custClassName="cost-btn-w t-btn"></CButton>
                </div>
              </div>
            </div>
            <div className="hero-right">
              <div className="hero-slider">
                <HomeHeroCarousel></HomeHeroCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="most-in-demand">
        <div className="house_container">

          <div className="top-title">
            <h2 className="heading-h2 title">Top Picks</h2>
            <div className="blank">
              <p>&nbsp;</p>
            </div>
            <div className="cta">
              <CButton fxToRun={() => navigate(urlPrefix() + "/library-overview?cat=" + categoryIds.in_demand)} label="See More" custClassName="cost-btn t-btn"></CButton>
            </div>
          </div>

          <div className="demand-slider">
            <MostDemandCarousel></MostDemandCarousel>
          </div>

        </div>
      </div>

      <div className="most-in-demand repeat fiction">
        <div className="house_container">

          <div className="top-title">
            <h2 className="heading-h2 title">Fiction</h2>
            <div className="blank">
              <p>&nbsp;</p>
            </div>
            <div className="cta">
              <CButton fxToRun={() => navigate(urlPrefix() + "/library-overview?cat=" + categoryIds.fiction)} label="See More" custClassName="cost-btn t-btn"></CButton>
            </div>
          </div>

          <div className="demand-slider">
            <FictionCarousel></FictionCarousel>
          </div>
        </div>
      </div>

      <div className="most-in-demand repeat">
        <div className="house_container">

          <div className="top-title">
            <h2 className="heading-h2 title">Non-Fiction</h2>
            <div className="blank">
              <p>&nbsp;</p>
            </div>
            <div className="cta">
              <CButton fxToRun={() => navigate(urlPrefix() + "/library-overview?cat=" + categoryIds.fantasy)} label="See More" custClassName="cost-btn t-btn"></CButton>
            </div>
          </div>

          <div className="demand-slider">
            <NonFictionCarousel></NonFictionCarousel>
          </div>

        </div>
      </div>

      <section className="picked-titles">
        <div className="house_container">
          <div className="picked">
            <div className="pic-cell">
              <div className="picked-card dark">
                <img src={urlPrefix() + "/assets/image/MainLogo.svg"} alt="book imag"></img>
              </div>
            </div>
            <div className="pic-cell border-right">
              <div className="picked-card">
                <div className="picked-main">
                  <p className="picked-title">500+</p>
                  <p className="detail">Hand-picked titles</p>

                  <CButton label="See All" fxToRun={() => navigate(urlPrefix() + "/library-overview")} custClassName="cost-btn-w t-btn mobile"></CButton>

                </div>
              </div>
            </div>
            <div className="pic-cell">
              <div className="picked-card">
                <div className="cta">
                  <CButton label="See All" fxToRun={() => navigate(urlPrefix() + "/library-overview")} custClassName="cost-btn-w t-btn"></CButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="becoming-member">
        <div className="house_container">
          <div className="image">
            <img src={urlPrefix() + "/assets/image/christin-hume.png"} alt="book imag"></img>
          </div>
          <div className="content">
            <h2 className="heading-h2">Becoming a Member is Easy.</h2>
            <p className="detail">Our members can borrow from a wide range of specially selected books with free pick-up and delivery.</p>
            <div className="cta">
              <CButton fxToRun={() => navigate(urlPrefix() + "/join-us")} label="Join Us" custClassName="white-btn-w t-btn"></CButton>
            </div>
          </div>
        </div>
      </section>

      <section className="h-before-footer">
        <div className="house-row">
          <div className="title">
            <h4 className="heading-h2"><span>About</span> House of Books</h4>
          </div>
          <div className="detail">
            <p>House of Books was established to create a community of readers. As an affordable means for people to rediscover the pleasure of reading a… <a onClick={() => navigate(urlPrefix() + "/our-story")}>Read More</a></p>
          </div>
          {/* <div className="cta">
            <CButton fxToRun={()=>navigate(urlPrefix() +  "/library-overview")} label="See More" custClassName="cost-btn t-btn"></CButton>
          </div> */}
        </div>
      </section>

    </React.Fragment>
  );
};
export default Home;